<template>
    <div  class="guide">
        <div>
            <el-dialog title="提示" :visible.sync="classBeginDialogVisible" :modal-append-to-body="false" :close-on-click-modal="false" :close-on-press-escape="false" width="0%">
                <div class="checkSucCard">
                    <div class="checkSuc">
                        <div class="tip"></div>
                        <div class="sucClassTip">
                            <div class="sucTipMessage">
                                <div class="beginClassTipOne">{{beginClassTipOne}}</div>
                                <div class="beginClassTipTwo">{{beginClassTipTwo}}<span>？</span></div>
                            </div>
                        </div>
                        <div class="operation">
                            <div class="goBack" @click="continueClass">是</div>
                            <div class="sure" @click="signOut">否</div>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        data() {
            return {
                classBeginDialogVisible: true,
                beginClassTipOne: '您接下来还有一节课',
                beginClassTipTwo: '是否继续上课',
            };
        },
        mounted() {
            
        },
        methods: {
            ...mapActions(['logout']),
            continueClass(){
                this.$router.push({
                    path: "home",
                });
            },
            signOut(){
                this.logout();
            }
        },
    };
</script>
<style lang="less" scoped>
.guide{
    width: 100%;
    height: 100%;
    background: url("../../assets/image/guide.png") center/cover no-repeat;
}

    .checkSucCard{
        position: fixed;
        left: 50%;
        top: 48%;
        width: 760px;
        height: 475px;
        background: url("../../assets/image/bg-login-card.png") center/cover no-repeat;
        transform: translateY(-50%) translateX(-50%);
        .checkSuc{
            position: absolute;
            left: 65px;
            top: 40px;
            width: 630px;
            height: 410px;
            background: #FFFCFD;
            border-radius: 135px;
            .tip{
                position: absolute;
                left: 50%;
                top: -16%;
                width: 400px;
                height: 110px;
                background: url("../../assets/image/tip.png") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .sucIcon{
                position: absolute;
                left: 50%;
                top: 27%;
                width: 170px;
                height: 183px;
                background: url("../../assets/image/checkSuc.gif") center/cover no-repeat;
                transform: translateY(-50%) translateX(-50%);
            }
            .sucTip{
                position: absolute;
                top: 52%;
                width: 100%;
                .sucTipMessage{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .msgOne{
                        letter-spacing:8px;
                    }
                    .msgTwo{
                        letter-spacing:8px;
                        margin-top: 10px;
                    }
                    .msgThree{
                        word-spacing: 10px;
                        margin-top: 30px;
                        color: #2CBD74;
                    }
                    .beginClassTipOne{
                        letter-spacing:8px;
                    }
                    .beginClassTipTwo{
                        letter-spacing:8px;
                    }
                }
            }
            .sucClassTip{
                position: absolute;
                top: 20%;
                width: 100%;
                .sucTipMessage{
                    text-align: center;
                    font-size: 25px;
                    font-family: Noto Sans S Chinese;
                    font-weight: 400;
                    color: #939393;
                    .beginClassTipOne{
                        letter-spacing:8px;
                        margin-bottom: 30px;
                    }
                    .beginClassTipTwo{
                        letter-spacing:8px;
                    }
                }
            }
            .operation{
                position: absolute;
                left: 50%;
                top: 75%;
                width: 500px;
                height: 110px;
                transform: translateY(-50%) translateX(-50%);
                display: flex;
                flex-flow:  row nowrap;
                justify-content: space-between;
                align-items: center;
                letter-spacing:3px;
                .goBack{
                    width: 200px;
                    height: 60px;
                    border: 1px solid #2CBD74;
                    border-radius: 42px;               
                    color: #2CBD74;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
                .sure{
                    width: 200px;
                    height: 60px;
                    background: #2CBD74;
                    border-radius: 42px;
                    color: #FFFCFD;;
                    line-height: 60px;
                    font-size: 24px;
                    cursor: pointer;
                    text-align: center;
                }
            }
        }
    }
    .canSignIn{
            margin-top: 10px;
            font-size: 30px;
            color: #ffffff;
            width: 405px;
            height: 70px;
            padding: 0;
            border-radius: 48px;
            background: url("../../assets/image/btn-login.png") center -5px no-repeat;
            background-size: cover;
            border: none;
    }
    .btn{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 280px;
    }
</style>
